<template>
    <div class="company-contain">
        <div class="table-box">
            <el-table
                    :data="taskList"
                    class="college-table"
                    style="width: 100%;flex: 1;min-height: calc(100vh - 330px)"
                    size="medium"
                    ref="taskTable"
                    @selection-change="chooseStu"
                    :header-cell-style="{fontWeight: 'normal', color: '#666666',background:'#F5F5F5'}"
                    :cell-style="{fontSize: '12px',color: '#333'}"
            >
                <el-table-column
                        prop="index"
                        label="排行"
                        align="left"
                        width="150"
                        class-name="student-name"
                >
                    <template slot-scope="scope">
                        <div class="icon-background">
                            <img src="../../../assets/img/company/gold.png"
                                 v-if="scope.row.rank === 1" alt="">
                            <img src="../../../assets/img/company/silver.png"
                                 v-if="scope.row.rank === 2" alt="">
                            <img src="../../../assets/img/company/copper.png"
                                 v-if="scope.row.rank === 3" alt="">
                        </div>
                        <div class="rank-title" v-if="scope.row.rank > 3">{{scope.row.rank}}</div>
                    </template>
                </el-table-column>

                <el-table-column prop="video_author" width="200" label="播主信息" align="center " class-name="student-name">
                    <template slot-scope="scope">
                        <div class="showing">
                            <img class="imgBox" :src=scope.row.avatar>
                            <span class="showing-name">{{scope.row.nickname}}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="business_score" label="商业价值分" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.business_score}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="follower_count" label="粉丝数" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.follower_count}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="content_score" label="内容价值分" align="center" class-name="student-name">
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.content_score}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="exponent" label="指数" align="center" class-name="student-name">
                    <template slot="header" slot-scope="scope">
                        <el-tooltip class="item" effect="light" placement="top">
                            <div slot="content">
                                　　由账号近期的粉丝总数、当前文章的互动数据等加权<br/>
                                　　计算获得数值越高，上热门的几率越大。<br/>
                            </div>
                            <i class="el-icon-warning"></i>
                        </el-tooltip>
                        <span style="margin-left: 6px;">指数</span>
                    </template>
                    <template slot-scope="scope">
                        <span class="student-name-title">{{scope.row.exponent}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="250" align="center">
                    <template slot-scope="scope">
                        <div class="operation" @click="changeToDetail(scope.row)">
                            <div class="detail-btn">详情</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="activeName" type="selection" width="100" align="center"></el-table-column>
            </el-table>
        </div>
        <el-pagination
                class="pages-center"
                :current-page="adminPages.currentPageNum"
                :page-size="adminPages.eachPageNum"
                layout="prev, pager, next, jumper"
                :total="adminPages.total"
                @current-change="adminCurrentChange"
        ></el-pagination>
    </div>
</template>

<script>
    export default {
        name: 'Baidu',
        props: ['activeName', 'stuId'],
        data() {
            return {
                isAll: 0,
                radio: "0",
                date: "day",//类型日周月
                datetime: "",
                nickname: "",//搜索框搜索内容
                cate: "",//分类名称
                typeLsit: [], //分类列表
                task_condition: "",
                taskList: [],//任务列表
                //分页
                adminPages: {
                    currentPageNum: 1,
                    eachPageNum: 10,
                    total: 0
                },
                label: "单日涨粉",
            }
        },
        mounted() {
            this.getAllClass();
        },
        methods: {
            setStuId() {
                let arr = this.stuId.split(',')
                let newArr = []
                arr.forEach(item => {
                    this.taskList.forEach(item1 => {
                        if (item1.user_account_id == item) {
                            newArr.push(item1)
                        }
                    })
                })
                this.$nextTick(() => {
                    newArr.forEach(item => {
                        this.$refs.taskTable.toggleRowSelection(item);
                    })
                })
            },
            chooseStu(val) {
                this.$emit('stuList', val)
            },
            // 获取全部列表
            getAllClass() {
                let param = {
                    type: 2,
                    identifying: 2
                };
                this.$httpcompany.axiosGetBy(this.$api.intelligent_intelligentList, param, res => {
                    if (res.code == 200) {
                        this.taskList = res.data.data;
                        this.adminPages.total = res.data.total;
                        if (this.activeName) {
                            this.setStuId();
                        }
                    } else {
                        this.$message({
                            type: "error",
                            message: res.msg,
                            duration: 1000
                        });
                    }
                });
            },
            //分页
            adminCurrentChange(val) {
                this.adminPages.currentPageNum = val;
                this.getAllClass();
            },
            //跳到详情页
            changeToDetail(row) {
                this.$emit('saveForm', true)
                let param = {
                    id: row.user_account_id,
                    platform_type: 1,
                    type: 1,
                }
                this.$httpcompany.axiosGetBy(this.$api.get_account, param, res => {
                    if (res.code == 200) {
                        this.$router.push({
                            path: '/company/data/details',
                            query: {
                                id: row.user_account_id,
                                user_id: row.user_id
                            }
                        })
                    } else {
                        this.$message.error(res.message)
                    }
                })
            },
            //打开视频
            jumpToVideo(row) {
                window.open(row.video_url);
            },
        },

    }
</script>

<style scoped lang="scss">
    .company-contain {
        margin-bottom: 20px;
        background-color: #ffffff;

        .rank-title {
            width: 26px;
            height: 26px;
            background: #E0E2FF;
            border-radius: 50%;
            text-align: center;
            font-size: 18px;
            line-height: 26px;
        }

        .operation {
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .detail-btn {
                width: 80px;
                height: 36px;
                background: #FFFFFF;
                border: 1px solid #20C997;
                color: #20C997;
                border-radius: 18px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .showing {
            display: flex;
            align-items: center;
            /*width: 30px;*/
            height: 30px;
            margin-left: 20px;

            img {
                display: inline-block;
                height: 30px;
                width: 30px;
                margin-right: 10px;
                border-radius: 50%;
            }

            .showing-name {
                max-width: 260px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }

        ::v-deep .el-table {
            display: flex;
            flex-direction: column;
            height: calc(100% - 114px);
        }

        ::v-deep .el-table--border::after, ::v-deep .el-table--group::after, ::v-deep .el-table::before {
            background-color: transparent;
        }

        ::v-deep .el-table--medium td, ::v-deep .el-table--medium th {
            padding: 10px 9px;
        }

        ::v-deep .el-table__footer-wrapper, ::v-deep .el-table__header-wrapper {
            overflow: initial;
        }

        ::v-deep .el-table__body-wrapper {
            overflow: auto;
        }

    }
</style>
