<template>
    <div class="favorite">
        <div class="leftBox">
            <div class="tab_box">
                <el-tabs v-model="ShortTab" type="card" @tab-click="handleClick">
                    <el-tab-pane label="图文达人" name="first">
                        <PicExpert v-if="this.ShortTab == 'first'" @picList="handlePic" @saveForm="saveForm" :activeName="activeName"
                                   :stuId="stuId">
                        </PicExpert>
                    </el-tab-pane>
                    <el-tab-pane label="视频达人" name="second">
                        <VideoExpert v-if="this.ShortTab == 'second'" @videoList="handleVideo" @saveForm="saveForm" :activeName="activeName"
                                     :stuId="stuId">
                        </VideoExpert>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </div>
    </div>
</template>

<script>
    import PicExpert from "@/components/taskCenter/PicExpert";
    import VideoExpert from "@/components/taskCenter/VideoExpert";

    export default {
        name: "StuExpert",
        props: ['activeName', 'stuId'],
        components: {
            PicExpert,
            VideoExpert
        },
        data() {
            return {
                ShortTab: this.$route.query.ShortTab || 'first',
                videoList: {},//视频详情
            }
        },
        created() {
        },
        methods: {
            handlePic(val) {
                this.$emit('picList', val)
            },
            handleVideo(val) {
                this.$emit('videoList', val)
            },
            saveForm(val){
                this.$emit('saveForm', true)
            },
            handleClick(tab, event) {
            },
        }
    }
</script>

<style scoped lang="scss">
    .favorite {
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);

        .leftBox {
            width: 100%;

            .tab_box {
                display: flex;
                width: 100%;

                ::v-deep .el-tabs {
                    height: 100%;
                    width: 100%;
                }

                ::v-deep .el-tabs__header {
                    border-bottom: none;
                    font-size: 14px;
                    margin: 0;
                }

                ::v-deep .el-tabs__content {
                    height: 1%;
                    flex: 1;
                    background: #fff;
                    padding-top: 20px;
                    margin-bottom: 20px;
                }

                ::v-deep .el-tabs__header .is-active {
                    background: #fff;
                    font-size: 14px;
                    font-weight: 400;
                    color: #4D5AFF;
                    border-radius: 6px 6px 0 0;
                }

                ::v-deep .el-tabs__item {
                    border: none;
                    height: 52px;
                    line-height: 52px;
                }

                ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                    border: none;
                }

                ::v-deep .el-tab-pane {
                    height: 100%;
                }
            }
        }
    }

</style>