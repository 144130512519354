<template>
    <div class="company-contain">
        <div class="tab_box">
            <el-tabs v-model="ShortTab" type="card" @tab-click="handleClick">
                <el-tab-pane label="抖音" name="first">
                    <Douyin :activeName="activeName" :stuId="stuId" @stuList="handleList" @saveForm="saveForm"
                            v-if="this.ShortTab == 'first'">
                    </Douyin>
                </el-tab-pane>
                <el-tab-pane label="快手" name="second">
                    <KuaiShou v-if="this.ShortTab == 'second'">
                    </KuaiShou>
                </el-tab-pane>
                <el-tab-pane label="哔哩哔哩" name="third">
                    <BiliBili v-if="this.ShortTab == 'third'">
                    </BiliBili>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
    import Douyin from "./StuExpert/Douyin";
    import KuaiShou from "./StuExpert/KuaiShou";
    import BiliBili from "./StuExpert/BiliBili";

    export default {
        name: 'VideoExpert',
        props: ['activeName', 'stuId'],
        components: {
            Douyin,
            KuaiShou,
            BiliBili
        },
        data() {
            return {
                ShortTab: this.$route.query.ShortTab || 'first',
            }
        },
        mounted() {

        },
        methods: {
            handleList(val) {
                this.$emit('videoList', val)
            },
            saveForm(val){
                this.$emit('saveForm', true)
            },
            handleClick(tab, event) {
            },
        },
    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #2338E6;
        color: #ffffff;
    }

    .company-contain {
        margin-bottom: 20px;
        background-color: #ffffff;

        .tab_box {
            ::v-deep .el-tabs__header {
                display: flex;
                justify-content: center;
                border-bottom: 1px solid #EEEEEE !important;
                background: #ffffff !important;
            }

            ::v-deep .el-tabs__header .is-active {
                border-bottom: 2px solid #4D5AFF !important;
            }

            ::v-deep .el-tabs--card > .el-tabs__header .el-tabs__nav {
                display: flex !important;
                justify-content: space-around !important;
                width: 300px !important;
            }
        }
    }
</style>
